<div [formGroup]="formGroup">
    <mat-form-field class="mat-form-field-container">
        <mat-label>{{ label }}</mat-label>
        <mat-select [placeholder]="placeholder" [formControlName]="formControlSelect" (selectionChange)="onSelectionChanged($event)">
            <input class="mat-mdc-option input-select-filter" (keyup)="filter($event)" matInput type="text" [formControlName]="formControlFilter">
            <mat-option *ngFor="let item of itemsAux" [value]=" (valueKey ? item[valueKey] : item)">
                {{ ( title ? item[title] : item ) }}{{ ( title2 && item[title2] != null ? " " + item[title2] : "" ) }}
            </mat-option>
        </mat-select>
        <mat-icon *ngIf="icon.length > 0" class="icon-size-5" [svgIcon]="icon" matPrefix></mat-icon>
    </mat-form-field>
</div>