import { FuseNavigationItem } from "../../../../@fuse/components/navigation";

export const managementsItem: FuseNavigationItem = {
    id      : 'managements',
    title   : 'menu.management.title',
    type    : 'group',
    icon    : 'heroicons_outline:adjustments',
    children: [
        {
            id   : 'managements.residencia',
            title: 'module.residencia.menu',
            type : 'basic',
            icon : 'mat_outline:business',
            link : '/residencia'
        },
        {
            id   : 'settings.user',
            title: 'module.user.menu',
            type : 'basic',
            icon : 'heroicons_outline:user-circle',
            link : '/users'
        }
    ]
};