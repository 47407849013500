import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from './adapters/moment-utc-date.adapter';
import { InputSelectAddComponent } from './components/inputs/select-add/input-select-add.component';
import { InputSelectFilterComponent } from './components/inputs/select-filter/input-select-filter.component';
import { InputFilterAddComponent } from './components/inputs/input-filter-add/input-filter-add.component';
import { PercentPipe } from './pipes/percent-pipe.pipe';
import localeEs from '@angular/common/locales/es';
import { InputDateCheckComponent } from './components/inputs/input-date-check/input-date-check.component';
import { FuseAlertModule } from '../../@fuse/components/alert';
import { DragDropListComponent } from './components/drag-drop-list/drag-drop-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Registrar los datos de locale
registerLocaleData(localeEs, 'es');

const sharedComponents = [
    InputSelectAddComponent,
    InputSelectFilterComponent,
    InputFilterAddComponent,
    InputDateCheckComponent,
    DragDropListComponent
];

const sharedModules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    TranslocoModule,
    FuseAlertModule,
    DragDropModule
];

const pipes = [PercentPipe];

@NgModule({
    declarations: [...sharedComponents, ...pipes],
    imports: [...sharedModules],
    exports: [...sharedModules, ...sharedComponents, ...pipes],
    providers: [
        { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: LOCALE_ID, useValue: 'es' },
        DecimalPipe
    ]
})
export class SharedModule {}
