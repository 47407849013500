import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { environment } from '../../../environments/environment';

import { Role } from '../constants/roles.enum';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _userDetails: User;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._userDetails = value;
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    get currentUser(): User {
        return {...this._userDetails};
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    get userRole(): Role {
        return this._userDetails.role as Role;
    }

    hasRole(role: Role): boolean {
        if(!this.user) {
            return false;
        }

        // Can do anything
        if(this.user.role === Role.ADMIN || this.user.role === Role.OWNER) {
            return true;
        }

        // BASIC restrictions
        if(role === Role.BASIC) {
            return this.user.role === role;
        }

        return false;
    }

    /**
     * Get the current logged in user data
     */
    get(): Observable<User>
    {
        return this._httpClient.get<User>(environment.serverUrl + '/auth/whoami').pipe(
            tap((user) => {
                console.log("Whoami get user", user);
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}
