import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

const alarmRoutes: Route[] = [
    {
        path: 'alarm',
        loadChildren: () => import('app/modules/alarm/alarm.module').then((m) => m.AlarmModule)
    },
];

const presenceRoutes: Route[] = [
    {
        path: 'presence',
        loadChildren: () => import('app/modules/presence/presence.module').then((m) => m.PresenceModule)
    },
];

const mgmtRoutes: Route[] = [
    // residencia
    {
        path: 'residencia',
        loadChildren: () => import('app/modules/residencia/residencia.module').then((m) => m.ResidenciaModule)
    },
    // users
    {
        path: 'users',
        loadChildren: () => import('app/modules/user/user.module').then((m) => m.UserModule)
    },
];

const errorRoutes: Route[] = [
    // 403 Forbidden
    {
        path: '403-forbidden',
        loadChildren: () => import('app/modules/error/forbidden/forbidden.module').then((m) => m.ForbiddenModule)
    },
    // 404 & Catch all
    {
        path: '404-not-found',
        loadChildren: () => import('app/modules/error/not-found/not-found.module').then((m) => m.NotFoundModule)
    },
];

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/alarm'
    {path: '', pathMatch : 'full', redirectTo: 'alarm'},

    // Redirect signed-in user to the '/alarm'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'alarm'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            // {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)
            },
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            ...alarmRoutes,
            ...presenceRoutes,
            ...mgmtRoutes,
            ...errorRoutes
        ]
    },

    { path: '**', redirectTo: '404-not-found' }
];
