import { FuseNavigationItem } from "../../../../@fuse/components/navigation";

export const reportItem: FuseNavigationItem = {
    id      : 'reports',
    title   : 'menu.report.title',
    type    : 'group',
    icon    : 'heroicons_outline:home',
    children:[
        {
            id   : 'voice-report',
            title: 'module.voice-report.menu',
            type : 'basic',
            icon : 'heroicons_outline:document-report',
            link : '/alarm/voice-report'
        },
        {
            id   : 'reports.presence',
            title: 'module.presence-report.menu',
            type : 'basic',
            icon : 'heroicons_outline:document-report',
            link : '/presence/presence-report'
        },
        {
            id   : 'alarm-technique',
            title: 'module.alarm-technique.menu',
            type : 'basic',
            icon : 'heroicons_outline:document-report',
            link : '/alarm/alarm-technique'
        },
        {
            id   : 'general-report',
            title: 'module.general-report.menu',
            type : 'basic',
            icon : 'heroicons_outline:document-report',
            link : '/alarm/general-report'
        },
        {
            id   : 'position-report',
            title: 'module.position-report.menu',
            type : 'basic',
            icon : 'heroicons_outline:document-report',
            link : '/alarm/position-report'
        },
    ]
};