import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'input-select-filter',
    templateUrl: './input-select-filter.component.html',
    styleUrls: ['./input-select-filter.component.scss']
})
export class InputSelectFilterComponent implements OnInit, OnChanges {
    @Input() formGroup: UntypedFormGroup;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() formControlSelect: string;
    @Input() formControlFilter: string;
    @Input() icon: string = '';
    @Input() items: any[] = [];
    @Input() title: string = '';
    @Input() title2: string = '';
    @Input() valueKey: string = '';
    @Input() value: string = '';
    @Input() action($event: any): void {}
    @Output() selectionChange = new EventEmitter<any>();
    
    itemsAux: any[] = [];
    titleAux: string = '';

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
          this.itemsAux = this.items;
        }
      }

    ngOnInit(): void {
        this.titleAux = this.title;
        this.formGroup.addControl(this.formControlSelect, new FormControl(this.value));
        this.formGroup.addControl(this.formControlFilter, new FormControl());
    }

    filter(event) {
        const filterValue = event.target.value.toLowerCase();
        if(!filterValue) this.itemsAux = this.items;
        this.itemsAux = this.items.filter((item) => item[this.titleAux].toLowerCase().includes(filterValue));
    }

    onSelectionChanged(event: any) {
        this.selectionChange.emit(event.value);
    }
}
