/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { managementsItem } from './items/managements.item';
import { reportItem } from './items/reports.item';

export const basicNavigation: FuseNavigationItem[] = [
    reportItem,
];

export const ownerNavigation: FuseNavigationItem[] = [
    reportItem,
    managementsItem
];

export const adminNavigation: FuseNavigationItem[] = [
    reportItem,
    managementsItem
];

export const defaultNavigation: FuseNavigationItem[] = [
    reportItem
];