import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment';

export class MomentUtcDateAdapter extends MomentDateAdapter {
  createDate(year: number, month: number, date: number): moment.Moment {
    return moment.utc({ year, month, date });
  }

  today(): moment.Moment {
    return moment.utc();
  }

  parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    return moment.utc(value, parseFormat, this.locale, true);
  }
}